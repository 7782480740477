import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
      </ul>
    </div>
  </div>
)

export default Footer
